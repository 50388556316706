import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';

@Component({
  selector: 'app-pages-number',
  templateUrl: './pages-number.component.html',
  styleUrls: ['./pages-number.component.css']
})
export class PagesNumberComponent implements OnInit {

  pagesChoosed= 'try';
  static pagesChoosed: any;

  constructor(private service:WEBserviceService) { }

  ngOnInit() {
  }


  onServerChoosePagesMin () {
    this.service.pages = '5 - 8';
    this.service.summaryPrice = '4000 - 3000';
    // console.log(this.service.pages);
  }

  onServerChoosePagesNormal () {
    this.service.pages = '9 - 12';
    this.service.summaryPrice = '5000 - 4200';
    // console.log(this.service.pages);
  }

  onServerChoosePagesMax () {
    this.service.pages = '13+';
    this.service.summaryPrice = '6000+';
    // console.log(this.service.pages);
  }

}
