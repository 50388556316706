import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../../webservice.service';
@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.css']
})
export class HeaderContentComponent implements OnInit {

  constructor(private apiService: WEBserviceService) { }

  ngOnInit() {
  }

}
