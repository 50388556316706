import { Component, OnInit, Input } from '@angular/core';
import { FormGroup,FormBuilder, FormControl, Validators } from '@angular/forms';

import { WEBserviceService } from '../webservice.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  constructor(private service:WEBserviceService,private fb: FormBuilder,
              private router: Router) { }

  chooseWEB = '';
  chooseGallery = '';
  choosePages = '';
  chooseLanguage = '';
  chooseDesign = '';
  chooseAnalytics = '';
  chooseSummary = '';
  EnterName = '';
  finalForm: FormGroup;

  
  ngOnInit() {

    this.chooseWEB = this.service.Choosed;

    this.chooseGallery = this.service.gallery;

    this.choosePages = this.service.pages;

    this.chooseLanguage = this.service.language;

    this.chooseDesign = this.service.design;

    this.chooseAnalytics = this.service.analytics;

    this.chooseSummary = this.service.summaryPrice;

    // this.EnterName = this.service.EnterName;

    this.finalForm = this.fb.group({
      name:['',Validators.required],
      phone:['',Validators.required],
      email:['',[Validators.required,Validators.email]]
    })
  }

  sendSummary(){
    // console.log(this.finalForm.value);
    if(this.finalForm.valid){
      const currentFormValue = this.finalForm.value;
      const leadPayload: any = {};
      leadPayload.leadName = currentFormValue.name;
      leadPayload.leadPhone = currentFormValue.phone;
      leadPayload.leadEmail = currentFormValue.email;
      this.service.saveSummary(leadPayload).subscribe((res) =>{
        this.router.navigate(['/thank-you']);
  
  
        
        // console.log('name =' + name);
        // console.log('mail =' + name);
      });
    } else{
      this.finalForm.updateValueAndValidity({emitEvent: false});
    }
   
  }


}


