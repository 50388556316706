import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
  } from "@angular/router";
  import { Injectable } from "@angular/core";
  import { Observable } from "rxjs";
  
  import { UsersManagerService } from "./users-manager.service";
  
  @Injectable()
  export class UsersManagerUrl implements CanActivate {
    constructor(private UsersManagerService: UsersManagerService, 
                private router: Router) {}
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
      const isAuth = this.UsersManagerService.getConeectionStatus();
      if (!isAuth) {
        this.router.navigate(['/ad-login']);
      }
      return isAuth;
    }
  }