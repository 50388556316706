import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
import { UsersManagerService } from '../users-manager/users-manager.service';
@Component({
  selector: 'app-page-structure',
  templateUrl: './page-structure.component.html',
  styleUrls: ['./page-structure.component.css']
})
export class PageStructureComponent implements OnInit {

  constructor(private apiService: WEBserviceService,
              private UsersManagerService: UsersManagerService) { }

  ngOnInit() {

  }

}
