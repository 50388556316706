import { Component, OnInit, Output } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
@Component({
  selector: 'app-choose-website',
  templateUrl: './choose-website.component.html',
  styleUrls: ['./choose-website.component.css']
})
export class ChooseWebsiteComponent implements OnInit {

  // use the service
  constructor(private service:WEBserviceService) {  }

  ngOnInit() {
  }

// onClick - to sent data to summary
onServerChooseREGWEB() {
  this.service.Choosed = 'אתר תדמית';
  // console.log(this.service.Choosed);
}

onServerChooseStore() {
  this.service.Choosed = 'חנות';
  // console.log(this.service.Choosed);
}

onServerChooseDontKnow() {
  this.service.Choosed = 'לא ידוע';
  // console.log(this.service.Choosed);
}

}
