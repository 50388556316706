import { Component, OnInit ,Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { WEBserviceService } from '../../webservice.service';

@Component({
  selector: 'app-web-form',
  templateUrl: './web-form.component.html',
  styleUrls: ['./web-form.component.css']
})
export class WebFormComponent implements OnInit {

  @Input() form: FormGroup;
  constructor(private service:WEBserviceService) { }
  ngOnInit() {
    
  }

}
