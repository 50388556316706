import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WEBserviceService } from '../../webservice.service';
import { UsersManagerService } from '../../users-manager/users-manager.service';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {

  
  private loginListener: Subscription;

  constructor(private apiService: WEBserviceService,
              private UsersManagerService: UsersManagerService) { }

  
  userConnected2 = this.UsersManagerService.userConnected;

  ngOnInit() {
    
    this.loginListener = this.UsersManagerService
    .getLoginStatusListener()
    .subscribe(isConnected => {
      this.userConnected2 = isConnected;
    });
  }

  onLogout() {
    this.UsersManagerService.logout()
  }

  ngOnDestroy() {
    this.loginListener.unsubscribe();
  }


 

}
