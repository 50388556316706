import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { UserManagerData } from './user-manager-data.model';
// import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class UsersManagerService {

  userConnected = false; // the user status (connected or not)

  private token: string;
  private logoutTimer: any;
  private loginStatusListener = new Subject<boolean>();  // set the log in status status 

  constructor(private http: HttpClient, 
              private router: Router
              ) { }

  getToken() {
    return this.token;
  }

  getConeectionStatus() {
    return this.userConnected;
  }

  getLoginStatusListener() {
    // console.log("loginStatusListener:", this.loginStatusListener);
    // console.log(this.loginStatusListener);
    return this.loginStatusListener.asObservable();
  }

  createUser(email:string, password:string) {
    const userManagerData: UserManagerData = {email: email, password: password};
    this.http.post("https://webcalcbuilder.herokuapp.com/user/signup", userManagerData)
    .subscribe( response => {
      console.log(response);
      this.router.navigate(['/dashboard2']); 
    });
  }

  login(email: string, password: string) {
    // console.log("email");
    // console.log(email);
    // console.log("password");
    // console.log(password);
    const userManagerData: UserManagerData = {email: email, password: password};
    this.http.post<{token: string, expiresIn: number}>("https://webcalcbuilder.herokuapp.com/user/ad-login", userManagerData)
      .subscribe(response => {
        // console.log(response);
        const token = response.token;
        this.token = token;
        if (token) {
          const expireTime = response.expiresIn;
          // console.log("expireTime:");
          // console.log(expireTime);
          this.setLogoutTimer(expireTime);
          this.userConnected = true;
          this.loginStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expireTime * 1000);
          // console.log("expirationDate = ", expirationDate);
          this.saveTokenData(token, expirationDate);
          this.router.navigate(['/dashboard2']);          
        }
      }, error => {
        // console.log(error);
      });
  }
  
  logout() {
    this.token = null;
    this.loginStatusListener.next(false);
    this.userConnected = false;

    clearTimeout(this.logoutTimer);
    this.clearTokenData();
    this.router.navigate(['/ad-login']);
  } 

  // save the token in the locl storage
  private saveTokenData(token: string, expirationDate: Date) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
  }

  // clear the token from local storage 
  private clearTokenData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
  }

  // automatic login with token
  autoLoginUser() {
    const loginInformation = this.getTokenData();
    if (!loginInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = loginInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = loginInformation.token;
      this.userConnected = true;
      this.setLogoutTimer(expiresIn / 1000);
      this.loginStatusListener.next(true);
      console.log("you are login")
      this.router.navigate(['/dashboard2']);
    }
  }

  private setLogoutTimer(duration: number)  {
    console.log("Setting timer: " + duration);
    setTimeout(() => {
      this.logout();
    }, duration * 1000)
};

  // get the token from the local storage
  private getTokenData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate)
    }
  }

}
