import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css']
})
export class DesignComponent implements OnInit {

  constructor(private service: WEBserviceService) { }

  ngOnInit() {
  }


  // onClick - to sent data to summary
  onServerChooseDesignAmazing() {
    this.service.design = 'מדהים';
    // console.log (this.service.design);
  }

  // onClick - to sent data to summary
  onServerChooseDesignNice() {
    this.service.design = 'יפה';
    // console.log (this.service.design);
  }

  // onClick - to sent data to summary
  onServerChooseDesignBasic() {
    this.service.design = 'בסיסי';
    // console.log (this.service.design);
  }


}
