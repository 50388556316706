import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WEBserviceService } from '../webservice.service';
import { UsersManagerService } from '../users-manager/users-manager.service';
@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.css']
})
export class PageContentComponent implements OnInit {

  // userAfterLogin = false;
  // testconnected = true;

  private userAfterLogin: Subscription;

  constructor(private apiService: WEBserviceService,
              private UsersManagerService: UsersManagerService ) { }



              // console.log(userAfterLogin);
  userConnectedAfterLogin = this.UsersManagerService.userConnected;

  
  ngOnInit() {
    this.userAfterLogin = this.UsersManagerService
    .getLoginStatusListener()
    .subscribe(isConnected => {
      this.userConnectedAfterLogin = isConnected;
  });

}
  


}
