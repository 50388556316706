import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WEBserviceService {

  constructor(private http: HttpClient) { }

  apiUrl: string = environment.apiUrl;
//      service - page - select kind of website type
private _webChoosed: string;
get Choosed() :string {
  return this._webChoosed;
}

set Choosed(val:string) {
  this._webChoosed = val;
}

//     service - page - select gallery type
private _galleryChoosed: string;
get gallery() :string {
  return this._galleryChoosed;
}

set gallery(val:string) {
  this._galleryChoosed = val;
}


//     service - page - select number of pages 
private _pagesChoosed: string;
get pages() :string {
  return this._pagesChoosed;
}

set pages(val:string) {
  this._pagesChoosed = val;
}


//     service - page - select language
  private _languageChoosed: string;
  get language() :string {
    return this._languageChoosed;
  }

  set language(val:string) {
    this._languageChoosed = val;
}
  

//     service - page - select design
private _designChoosed: string;
get design() :string {
  return this._designChoosed;
}

set design(val:string) {
  this._designChoosed = val;
}


//     service - page - add analytics system
private _analyticsChoosed: string;
get analytics() :string {
  // console.log("analytics 1: ", this._analyticsChoosed)
  return this._analyticsChoosed;
}

set analytics(val:string) {
  // console.log("analytics 2: ", this.analytics)
  this._analyticsChoosed = val;
}

//     service - page - summary Price system
// private _choosedSummaryPrice = '2000 - 3500 ש"ח';
private _choosedSummaryPrice : string;
get summaryPrice() :string {
  return this._choosedSummaryPrice;
}

set summaryPrice(val:string) {
  this._choosedSummaryPrice = val;
}

//add a parameter for the user form
saveSummary(leadDetails: any) : any {
const payload : SummaryDetails   = {} as SummaryDetails;
payload.websiteType = this._webChoosed;
payload.galleryPage = this._galleryChoosed;
payload.numberPages = this._pagesChoosed;
payload.languages = this._languageChoosed;
payload.designLevel = this._designChoosed;
payload.addAnalytics = this._analyticsChoosed;
payload.estimatedPrice = this._choosedSummaryPrice;
payload.leadName = leadDetails.leadName;
payload.leadPhone = leadDetails.leadPhone;
payload.leadEmail = leadDetails.leadEmail;

// console.log(payload);

return this.http.post(`https://webcalcbuilder.herokuapp.com/api/save`,payload);

// const payload : SummaryDetails = {
//   websiteType: '12312313',
//   galleryPage: ,
//   ad 
// }

}

getStats() {
  return this.http.get(`https://webcalcbuilder.herokuapp.com/stats`);
}



}

interface SummaryDetails{
  websiteType: string , 
  galleryPage: string, 
  numberPages: string , 
  languages: string , 
  designLevel: string ,
  addAnalytics: string , 
  estimatedPrice : string,
  leadName : string,
  leadPhone: string,
  leadEmail: string
}