import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import { WEBserviceService } from '../webservice.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit  {

  
  public barChartOptions: ChartOptions = {
  responsive: true,
  // We use these empty structures as placeholders for dynamic theming.
  scales: { xAxes: [{}], yAxes: [{}] },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    }
  }
};

data: number;

 barWebsiteType: Label[] = ['סוג האתר'];
 barbarGalleryType: Label[] = ['סוג הגלריה'];
 barNumberPages: Label[] = ['כמות עמודים'];
 barLanguages: Label[] = ['שפות'];
 barDesignLevel: Label[] = ['רמת עיצוב'];
 barAddAnalytics: Label[] = ['מערכת אנליטיקס'];


 barChartType: ChartType = 'bar';
  barChartLegend = true;
public barChartPlugins = [pluginDataLabels];
barChartData: ChartDataSets[] = [{data:[]}];
barChartDataGallery: ChartDataSets[] =[{data:[]}];
chartData:any [] = [{data:[]}];
numberPages: ChartDataSets[] = [{data:[]}];
languages: ChartDataSets[] = [{data:[]}];
designLevel: ChartDataSets[] = [{data:[]}];
addAnalytics: ChartDataSets[] = [{data:[]}];

constructor(private apiService: WEBserviceService) { }

ngOnInit() {
  this.apiService.getStats().subscribe((res:any) =>{
    // console.log(res);
    this.barChartData=  [
        { data:[res.websiteType.PercentageA],label:'תדמית'},
        { data:[res.websiteType.PercentageB],label:'חנות'},
        { data:[res.websiteType.PercentageC],label:'לא ידוע'}
      ],
      this.barChartDataGallery = [
        { data:[res.galleryType.PercentageA],label:'מעורב'},
        { data:[res.galleryType.PercentageB],label:'גלריית תמונות'},
        { data:[res.galleryType.PercentageC],label:'גלריית וידאו'}
      ],
      this.numberPages = [
        { data:[res.numberPages.PercentageA],label:'עמודים 9 - 12'},
        { data:[res.numberPages.PercentageB],label:'5 - 8'},
        { data:[res.numberPages.PercentageC],label:'13+'}
      ],
      this.languages = [
        { data:[res.languages.PercentageA],label:'אתר בעברית'},
        { data:[res.languages.PercentageB],label:'אתר באנגלית'},
        { data:[res.languages.PercentageC],label:'לא ידוע'}
      ],
      this.designLevel = [
        { data:[res.designLevel.PercentageA],label:'יפה'},
        { data:[res.designLevel.PercentageB],label:'מדהים'},
        { data:[res.designLevel.PercentageC],label:'בסיסי'}
      ],
      this.addAnalytics = [
        { data:[res.addAnalytics.PercentageA],label:'לא צריך'},
        { data:[res.addAnalytics.PercentageB],label:'חברו אותי'},
        { data:[res.addAnalytics.PercentageC],label:'לא ידוע'}
      ];
  });
}

// events
public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  // console.log(event, active);
}

public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  // console.log(event, active);
}

}
