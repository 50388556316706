import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  // use the service
  constructor(private service:WEBserviceService) { }

  ngOnInit() {
  }

// onClick - to sent data to summary
onServerChooseLanguageMore() {
  this.service.language = 'אתר באנגלית';
  // console.log(this.service.language);
}

onServerChooseLanguage() {
  this.service.language = 'אתר בעברית';
  // console.log(this.service.language);
}

onServerChooseLanguageDontKnow() {
  this.service.language = 'לא יודע';
  // console.log(this.service.language);
}



}
