import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
@Component({
  selector: 'app-add-gallery',
  templateUrl: './add-gallery.component.html',
  styleUrls: ['./add-gallery.component.css']
})
export class AddGalleryComponent implements OnInit {

  galleryChoosed= 'try';
  static galleryChoosed: any;
  
  constructor(private service:WEBserviceService) { }

  ngOnInit() {
  }


  onServerChooseImages() {
    this.service.gallery = 'גלריית תמונות';
    // console.log(this.service.gallery);
  }
  
  onServerChooseGallery() {
    this.service.gallery = 'מעורב';
    // console.log(this.service.gallery);
  }

  onServerChooseVideo() {
    this.service.gallery = 'גלריית וידאו';
    // console.log(this.service.gallery);
  }


}
