import { Component, OnInit } from '@angular/core';
import { WEBserviceService } from '../webservice.service';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  constructor(private service:WEBserviceService) { }

  ngOnInit() {
  }

  // onClick - to sent data to summary
onServerChooseAnalyticsConnect(){
  this.service.analytics = 'חברו אותי';
  // console.log(this.service.analytics);
  
}

// onClick - to sent data to summary
onServerChooseAnalyticsDontConnect(){
  this.service.analytics = 'לא צריך';
  // console.log(this.service.analytics);
  
}

// onClick - to sent data to summary
onServerChooseAnalyticsDontKnow(){
  this.service.analytics = 'לא יודע';
  // console.log(this.service.analytics);
  
}

}
