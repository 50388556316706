// angular liber
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";

import { AuthInterceptor } from "./users-manager/auth-interceptor";

// angular app pages:
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { ChooseWebsiteComponent } from './choose-website/choose-website.component';
import { AddGalleryComponent } from './add-gallery/add-gallery.component';
import { PagesNumberComponent } from './pages-number/pages-number.component';
import { LanguageComponent } from './language/language.component';
import { DesignComponent } from './design/design.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SummaryComponent } from './summary/summary.component';
import { WebFormComponent } from './summary/web-form/web-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

import { PageStructureComponent } from './page-structure/page-structure.component';
import { HeaderContentComponent } from './header/header-content/header-content.component';
import { PageContentComponent } from './page-content/page-content.component';
import { SidebarComponent } from './header/sidebar/sidebar.component';
import { ToolbarComponent } from './header/toolbar/toolbar.component';

import { ChartsModule } from 'ng2-charts';


import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatProgressSpinnerModule, MatCardModule} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule } from '@angular/material/core';
import { LoginComponent } from './users-manager/login/login.component';
import { SignupComponent } from './users-manager/signup/signup.component';
import { UsersManagerUrl } from './users-manager/users-manager-url.guard';

// import { userInfo } from 'os';
// const routes: Routes = [
//   {path: '', component: StartComponent,
//     children: [
//       { path: 'choose-website', component: ChooseWebsiteComponent },

//     ]
//   }
// ];


const routes: Routes = [
  { path: '', component: StartComponent },
  { path: 'start', component: StartComponent },
  { path: 'choose-website', component: ChooseWebsiteComponent },
  { path: 'gallery', component: AddGalleryComponent },
  { path: 'pages-number', component: PagesNumberComponent },
  { path: 'language', component: LanguageComponent },
  { path: 'design', component: DesignComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'thank-you', component: ThankYouComponent },

  
  // web app routes path:
  { path: 'dashboard2', component: PageStructureComponent, canActivate: [UsersManagerUrl] },
  { path: 'ad-login', component: LoginComponent },
  { path: 'signup', component: SignupComponent }
];



@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    ChooseWebsiteComponent,
    AddGalleryComponent,
    PagesNumberComponent,
    LanguageComponent,
    DesignComponent,
    AnalyticsComponent,
    SummaryComponent,
    WebFormComponent,
    DashboardComponent,
    PageStructureComponent,
    HeaderContentComponent,
    PageContentComponent,
    SidebarComponent,
    ToolbarComponent,
    LoginComponent,
    SignupComponent,
    ThankYouComponent
    


  ],

  // exports: [
  //   MatTabsModule
  // ],

  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    MatInputModule,
    MatTabsModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatCardModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    UsersManagerUrl
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
