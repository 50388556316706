import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsersManagerService } from '../users-manager.service';
import { Router } from '@angular/router';
@Component({ 
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public UsersManagerService: UsersManagerService,
              private router:Router) { }

  ngOnInit() {
    this.UsersManagerService.autoLoginUser();
  }

  isLoading = false;

  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.isLoading = true;
    this.UsersManagerService.login( form.value.email, form.value.password);

    setTimeout( () => {
      // console.log("timeOut-in")
      this.isLoading = false;
    }, 5000);
      
    
  }

}
